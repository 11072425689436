@import '../../styles/variables.scss';

.container {
    display: inline-grid;
    place-items: center;

    svg {
        display: block;
        width: 100%;
        height: 100%;
        color: inherit;
    }

    path[stroke],
    line[stroke],
    rect[stroke],
    circle[stroke] {
        stroke: currentColor;
    }

    path[fill],
    line[fill],
    rect[fill],
    circle[fill] {
        fill: currentColor;
    }
}
