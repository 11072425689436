@import "../../../styles/variables.scss";

.button {
    height: 48px;
    border-radius: 8px;
    display: flex;
    padding: 0 12px;
    justify-content: center;
    align-items: center;
    background-color: $primaryColor;
    border: 1px solid transparent;
    cursor: pointer;
    letter-spacing: 0.25px;
    font-size: 16px;
    font-weight: 700;
    color: $white;

    .icon {
        display: flex;
        align-items: center;
        margin-right: 8px;
    }

    i {
        width: 20px !important;
        height: 20px !important;
    }

    svg {
        fill: $white;
    }

    &:disabled {
        background-color: $blackOpacity12;
        color: $blackOpacity38;
        cursor: not-allowed;

        svg {
            fill: $blackOpacity38;
        }
    }

    &.ghost {
        background-color: transparent;
        border: 1px solid $primaryColor;
        color: $primaryColor;

        svg {
            fill: $primaryColor;
        }

        &:disabled {
            background-color: transparent;
        }
    }

    &.large {
        height: 56px;
        font-size: 20px;

        i {
            width: 24px !important;
            height: 24px !important;
        }

        svg {
            width: 24px;
            height: 24px;
        }
    }

    &.small {
        height: 40px;
        font-size: 12px;
        padding: 0 10px;
    }

    &.xs {
        height: 24px;
        font-size: 12px;
        padding: 0 8px;
        border-radius: 4px;
        font-weight: normal;

        i {
            width: 14px !important;
            height: 14px !important;
        }

        svg {
            width: 14px;
            height: 14px;
        }

        .icon {
            margin-right: 4px;
        }
    }
}

.textUpperCase {
    text-transform: uppercase;
}

.fullWidth {
    width: 100%;
}

.red {
    background: $red;

    &.ghost {
        background-color: transparent;
        border: 1px solid $red;
        color: $red;

        svg {
            fill: $red;
        }
    }
}

.yellow {
    background: $yellow;
    color: $blackOpacity88;

    svg {
        fill: $blackOpacity88;
    }

    &.ghost {
        background-color: transparent;
        border: 1px solid $yellow;
        color: $yellow;

        svg {
            fill: $yellow;
        }
    }
}

.blue {
    background: $blueSecondary;

    &.ghost {
        background-color: transparent;
        border: 1px solid $blueSecondary;
        color: $blueSecondary;

        svg {
            fill: $blueSecondary;
        }
    }
}

.white {
    background: $white;
    border: 1px solid #0000001F;
    color: $black;

    svg {
        fill: $black;
    }
}

.black {
    background: $black12;
    color: $white;

    &.ghost {
        background-color: transparent;
        border: 1px solid $black12;
        color: $black12;

        svg {
            fill: $black12;
        }
    }
}

.gray {
    background: $blackOpacity12;
    color: $blackOpacity60;

    svg {
        fill: $blackOpacity60;
    }

    &.ghost {
        background-color: transparent;
        border: 1px solid $blackOpacity12;
        color: $blackOpacity60;

        svg {
            fill: $blackOpacity60;
        }
    }
}
