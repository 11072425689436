@import '../../../../styles/variables';

.contributorsCards {
    > p {
        color: $blackOpacity60;
    }
}

.card {
    border-radius: 8px;
    background: $white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.30), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
    margin-top: 16px;
    padding: 16px;

    header {
        display: flex;
        margin-bottom: 16px;

        .img {
            width: 64px;
            min-width: 64px;
            margin-right: 16px;

            img {
                //width: 64px;
                //height: 64px;
                //object-fit: cover;
                //border-radius: 50%;
            }
        }

    }

    .titleBox {
        a { color: $blackOpacity88 };
    }

    .types {
        display: flex;
        flex-wrap: wrap;
        margin-top: 8px;

        button {
            margin-right: 4px;
            margin-bottom: 4px;
        }
    }

    .txt {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: $blackOpacity60;
        margin-bottom: 16px;
    }

    .btn {

    }
}
