@import "../../../../styles/variables.scss";

.txt {
    color: rgba(0, 0, 0, 0.60);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 12px;
}

.box {
    display: flex;
    padding: 12px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background: $white;
    margin-top: 8px;
}

.iconBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    min-width: 80px;
    flex-shrink: 0;
    border-radius: 4px;
    background-color: $primaryColor;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.20), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);

    svg {
        fill: $white;
    }
}


.info {
    margin-left: 16px;
    width: 100%;

    strong {
        color: rgba(0, 0, 0, 0.87);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        display: block;
        margin-bottom: 8px;
    }

    span {
        display: inline-flex;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }
}

.checkBox {
    margin-left: 16px;
    margin-right: 10px;
}

.checkMark {
    width: 24px;
    height: 24px;
    display: flex;
    background-color: $black12;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}

.btn {
    margin-top: 16px;
}


.customButton {
    padding: 0;
    background-color: transparent;
    border: none;
    width: 100%;
    text-align: left;
}
