@import "../../../../styles/variables.scss";

.wrap {
    display: flex;
}

.img {
    width: 96px;
    min-width: 96px;
    margin-right: 24px;

    img {
        width: 100%;
        object-fit: cover;
        border-radius: 4px;
    }
}


.info {
    h4 {
        margin-bottom: 8px;

        i {
            position: relative;
            bottom: -4px;
        }
    }
}

.types {
    display: flex;
    flex-wrap: wrap;

    button {
        margin-right: 8px;
        margin-bottom: 8px;


        em {
            font-style: normal;
        }
    }
}

.address {
    margin-top: 4px;
    color : $blackOpacity60;

    i {
        position: relative;
        bottom: -2px;
    }

    svg {
        fill: $blackOpacity60;
    }
}

.btnBox {
    margin-top: 16px;
}
