@import "../../../../../styles/variables";

.backBtnBox {
    button {
        background: transparent;
        border: none;
        padding: 0;
    }

    button, a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
    }

    svg {
        width: 24px;
        height: 24px;
        opacity: 0.87;
    }
}

