@import "../../../../styles/variables.scss";

.stats {
    display: flex;
    padding: 24px 0;
    overflow-y: scroll;
    min-width: 100%;
    margin-top: 24px;
    align-items: flex-start;

    border-top: 1px solid $blackOpacity12;
    border-bottom: 1px solid $blackOpacity12;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.stat {
    padding: 0 16px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.4px;
    text-align: center;
    border: 0;
    background-color: transparent;
    box-shadow: none;


    label {
        min-width: 74px;
        color: $blackOpacity60;
        display: block;
        margin-bottom: 4px;
        max-width: 130px;
    }

    strong {
        display: block;
        color: $blackOpacity88;
        margin: 4px 0 0;
        font-weight: bold;
    }

    &.selectable {
        strong {
            color: $blue600;
        }
    }
}

.icon {
    min-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        fill: $blackOpacity88;
    }
}

.publisherBox {
    margin-top: 24px;
    border-top: 1px solid $blackOpacity12;
    padding-top: 24px;

    &:first-child {
        border-top: 0;
        padding-top: 0;
        margin-top: 0;
    }
}
