@import '../../../styles/variables.scss';

.rateStars {
    line-height: 0;

    button {
        padding: 0;
        margin: 0;
        background: none;
        border: none;
        outline: none;
        line-height: 1;
    }

    svg {
        cursor: pointer;
        fill: $blackOpacity38
    }

    .filled {
        svg {
            fill: $yellowDark;
        }
    }
}
