@import '../../../../styles/variables';

.tag {
    text-align: center;
    margin-bottom: 8px;

    span {
        padding: 8px;
        display: inline-flex;
        border-radius: 24px;
        background: $red;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.30), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
        color: $white;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.4px;
    }
}

.label1, .label2 {
    color: $blackOpacity60;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}

.label1 {
    margin-top: 14px;
    margin-bottom: -16px;
}

.label2 {
    margin-top: -16px;
    margin-bottom: 14px;
}

.count {
    text-align: center;
    color: $blackOpacity87;
    font-family: $titlesFont;
    font-size: 96px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -1px;
    margin-bottom: 8px;
}

.desc {
    color: $blackOpacity88;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 16px;
}
