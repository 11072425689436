@import "variables";
@import "reset";
@import "fonts/fonts";
@import "typography";

:root {
    //--toastify-color-light: #fff;
    //--toastify-color-dark: #121212;
    --toastify-color-info: #383258;
    --toastify-color-success: #0B8D00;
    --toastify-color-error: #FF563C;
}

/* apply a natural box layout model to all elements, but allowing components to change */
*, *:before, *:after {
    box-sizing: border-box;
}

html {
    //height: -webkit-fill-available;
    height: 100%;
}

body {
    margin: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    background: $mainBg;
    font-family: $textFont;
    font-size: 16px;
    min-height: 100%;
    //min-height: -webkit-fill-available;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
}

#root {
    flex: 1;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

main {
    //padding: 24px;
    max-width: $maxContentWidth;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    flex: 1;
    width: 100%;
}

.show-more-less-clickable {
    font-weight: 500;
    color: $black12;
}


.Toastify__toast-container {
    padding: 10px;
}

.Toastify__toast {
    border-radius: 8px;
    margin-bottom: 12px;

    .Toastify__close-button {
        opacity: 1;
        padding: 2px;
    }
}

.Toastify__toast--info, .Toastify__toast--success {
    border: 1px solid $primaryColor;
    background-color: $primaryColor;
    color: $white;

    svg {
        fill: $white;
    }
}

.Toastify__toast--error {
    border: 1px solid $red;
    background-color: $red;
    color: $white;

    svg {
        fill: $white;
    }
}

.show-more-less-clickable {
    font-weight: bold;
}
