@import "../../../styles/variables.scss";

.LX5 {
    font-size: $size5XL;
    line-height: 1.12;
    letter-spacing: -1px;
}

.LX4 {
    font-size: $size4XL;
    line-height: 1.2;
    letter-spacing: -0.5px;
}

.LX3 {
    font-size: $size3XL;
    line-height: 1.2;
}

.LX2 {
    font-size: $size2XL;
    line-height: 1.2;
}

.XL {
    font-size: $sizeXL;
    line-height: 1.2;
}

.L {
    font-size: $sizeL;
    line-height: 1.2;
}

.M {
    font-size: $sizeM;
    line-height: 1.2;
}

.S {
    font-size: $sizeS;
    line-height: 1.2;
}

.XS {
    font-size: $sizeXS;
    line-height: 1.2;
}

.XXS {
    font-size: $sizeXXS;
    line-height: 1.5;
    letter-spacing: 1.5px;
}

.success {
    color: $green;
}

.error {
    color: $red;
}

.warning {
    color: $orange;
}


.uppercase {
    text-transform: uppercase;
}

.bold {
    font-weight: bold;
}
