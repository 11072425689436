@import "../../../../styles/variables.scss";

.header {
    padding: 0 16px;
    display: flex;
    align-self: center;
    justify-content: space-between;
    position: relative;
    z-index: 3;
    width: 100%;
    height: 56px;
    color: $black;
    font-size: 18px;

    svg {
        fill: $black;
        width: 24px;
        height: 24px;
    }

    &.primary {
        background-color: $primaryColor;
        color: $white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.20), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);

        svg {
            fill: $white;
        }
    }

    &.sticky {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
    }
}

.actions {
    flex: 1;
    display: flex;
    align-items: center;

    a {
        line-height: 0;
        display: flex;
    }
}

.center {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-family: $titlesFont;
    font-weight: bold;
}


.rightSlot {
    justify-content: flex-end;
}
