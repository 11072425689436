@import '../../../../styles/variables';

.contributor {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    padding-top: 16px;
    border-top: 1px solid $blackOpacity12;

    p {
        margin: 0 0 0 8px;
        color: $blackOpacity88;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-basis: 100%;
        flex: 1;
    }

    .avatarBox {
        min-width: 48px;
    }

    .types {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        button {
            margin-left: 8px;
            margin-bottom: 8px;

            em {
                font-style: normal;
            }
        }

        &.hasMore {
            max-width: 170px;

            button {
                em {
                    max-width: 60px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    min-width: 0;
                }
            }

            @media only screen and (min-width: 390px) {
                max-width: 220px;
            }
        }
    }
}
