@import '../../../../styles/variables';

.cover {
    position: relative;
    display: flex;
    justify-content: center;

    picture {
        display: block;
        position: relative;
    }

    img {
        max-height: 320px;
    }
}

.favoriteBtn {
    align-self: flex-end;
    margin-right: 20px;
    margin-bottom: -5px;
    margin-left: -5px;
}

.shareBtn {
    align-self: flex-start;
    margin-left: 20px;
    margin-top: -5px;
    margin-right: -5px;
}

.favoriteBtn,
.shareBtn {
    padding: 5px;
    background: 0;
    border: 0;
    box-shadow: none;

    svg {
        fill: $blackOpacity88;
    }
}

.tags {
    position: absolute;
    left: 0;
    right: 0;
    top: -12px;
    display: flex;
    justify-content: center;
}

.campaignBtn {
    height: 24px;
    padding: 0 8px;
    border-radius: 24px;
    background: $red;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.30), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
    color: $white;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.4px;
    border: 0;
    display: inline-flex;
    align-items: center;

    &:focus {
        outline: none;
    }
}
