@import '../../../styles/variables';

.root {
    padding: 0 0 16px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    width: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    padding: 0 16px;
    color: $black12;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;

    img {
        margin-bottom: 32px;
        object-fit: contain;
        max-width: 500px;
    }

    h2 {
        color: $black12;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 14px;
    }

    p {
        margin-bottom: 20px;
    }
}

.navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-bottom: 20px;

    li + li {
        margin-left: 7px;
    }

    button {
        width: 6px;
        height: 6px;
        padding: 0;
        margin: 0;
        border: 0;
        border-radius: 3px;
        background-color: rgba(0, 0, 0, 0.38);
        font-size: 0;

        transition: width 0.25s ease;
    }

    .selected {
        width: 24px;
        background-color: $primaryColor;
    }
}

.backBtnBox {
    min-width: 37px;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    padding: 0 16px;
}

.skipBtn {
    color: rgba(0, 0, 0, 0.60);
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    padding: 4px 0px;
    display: flex;
    min-height: 24px;
    align-items: center;
    border: 0;
    background: transparent;
}
