@import "../../../../../styles/variables.scss";

$progressColor: $primaryColor;
$progressMiniColor: $white;

.progressBarWrap {
}

.timings {
    display: flex;
    width: 100%;
    color: rgba(0, 0, 0, 0.60);
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.4px;
    margin-top: 6px;

    &.isMini {
        color: rgba(255, 255, 255, 0.38);
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0;
        margin-top: 4px;
    }
}

.audioTitle {
    flex: 1;
    text-align: center;
    padding: 0 12px;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.track {
    input[type="range"] {
        -webkit-appearance: none;
        margin-right: 15px;
        width: 100%;
        height: 4px;
        background: rgba(0, 0, 0, 0.14);
        border-radius: 5px;
        background-image: linear-gradient($progressColor, $progressColor);
        background-repeat: no-repeat;
    }

    /* Input Thumb */
    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: $progressColor;
        cursor: ew-resize;
    }

    input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: $progressColor;
        cursor: ew-resize;
    }

    input[type="range"]::-ms-thumb {
        -webkit-appearance: none;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: $progressColor;
        cursor: ew-resize;
    }

    input[type="range"]::-webkit-slider-thumb:hover {
        background: $progressColor;
    }

    input[type="range"]::-moz-range-thumb:hover {
        background: $progressColor;
    }

    input[type="range"]::-ms-thumb:hover {
        background: $progressColor;
    }

    /* Input Track */
    input[type=range]::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }

    input[type=range]::-moz-range-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }

    input[type="range"]::-ms-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }

    &.isMini {
        input[type="range"] {
            background: rgba(255, 255, 255, 0.38);
            background-image: linear-gradient($progressMiniColor, $progressMiniColor);
            background-repeat: no-repeat;
        }

        /* Input Thumb */
        input[type="range"]::-webkit-slider-thumb {
            background: $progressMiniColor;
        }

        input[type="range"]::-moz-range-thumb {
            background: $progressMiniColor;
        }

        input[type="range"]::-ms-thumb {
            background: $progressMiniColor;
        }

        input[type="range"]::-webkit-slider-thumb:hover {
            background: $progressMiniColor;
        }

        input[type="range"]::-moz-range-thumb:hover {
            background: $progressMiniColor;
        }

        input[type="range"]::-ms-thumb:hover {
            background: $progressMiniColor;
        }
    }
}
