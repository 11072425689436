@import "../../../styles/variables";

.box {
    position: relative;
}

.label {
    font-size: 13px;
    line-height: 14px;
    position: absolute;
    left: 9px;
    padding-left: 2px;
    padding-right: 2px;
    top: 50%;
    z-index: 1;
    pointer-events: none;
    transition: 0.2s ease all;
    color: rgba(0, 0, 0, 0.60);
    transform: translate(0, -50%) scale(1);

    &.large {
        font-size: 16px;
        line-height: 18px;
    }
}

.floated {
    color: rgba(0, 0, 0, 0.60);
    padding-top: 2px;
    padding-right: 4px;
    padding-left: 3px;
    top: -2px;
    font-size: 12px;
    font-weight: 400;

    &:before {
        content: '';
        height: 4px;
        width: 100%;
        background-color: $mainBg;
        position: absolute;
        left: 0;
        top: 50%;
        z-index: -1;
    }

    &.white {
        &:before {
            background-color: $white;
        }
    }
}

.required {
    color: $red;
    margin-left: 4px;
}
