@import "../../../../../styles/variables";

.top {
    color: $blackOpacity60;
    text-align: center;
    margin-bottom: 16px;

    h5 {
        color: $blackOpacity87;
        margin-bottom: 4px;
    }
}

.avatar {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}


.subTitle {
    color: $blackOpacity87;
    text-align: center;
    font-weight: bold;
    margin-bottom: 12px;
}

.field {
    margin-bottom: 24px;
}


.relationships {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 18px;

    button {
        margin: 0 2px 4px 2px;
    }
}
