@import "../../../../../styles/variables";

.top {
    color: $blackOpacity60;
    text-align: center;
    margin-bottom: 24px;

    h5 {
        color: $blackOpacity87;
        margin-bottom: 8px;
    }
}


.subTitle {
    color: $blackOpacity87;
    text-align: center;
    font-weight: bold;
    margin-bottom: 12px;
}

.btnBox {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;

    a {
        display: block;
        width: 100%;
    }
}

.hint {
    margin-top: 18px;
    color: $blackOpacity38;
    text-align: center;
    letter-spacing: 0.4px;
}

.langs {
    margin-bottom: 26px;

    p {
        text-align: center;
        margin-bottom: 16px;
        font-weight: bold;
    }
}
