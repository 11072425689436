@import "../../../../../styles/variables";

.miniPlayerBox {
    position: relative;
    background-color: rgba(235, 88, 67, 1);
    overflow: hidden;

    .closeButtons {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        justify-content: space-between;

        button {
            background-color: transparent;
            border: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 70px;
        }
    }
}

.miniPlayer {
    position: relative;
    transition: transform 200ms;
    min-height: 64px;
    width: 100%;
    background-color: $purple;
    z-index: 2;

    display: flex;
    align-items: center;

    picture {
        width: 64px;
        min-width: 64px;
        height: 64px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
    }

    .miniInfo {
        min-width: 0;
        flex: 1;
        padding: 0 18px 0 8px;
    }


    .miniBtnBox {
        min-width: 46px;

        button {
            border: 0;
            background: transparent;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
        }

        svg {
            fill: $white;
        }
    }

    .miniTitle, .miniAuthor {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;
    }

    .miniTitle {
        color: $white;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
    }

    .miniAuthor {
        color: rgba(255, 255, 255, 0.74);
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
    }
}


