@import "../../styles/variables.scss";

.root {
    padding: 0 0 24px;
}

.header {
    margin-bottom: 24px;
    position: sticky;
    left: 0;
    top: 0;
}

.textEllipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.content {
    padding: 0 16px;
}

.bookTitle {
    line-height: 28px;
    color: $blackOpacity88;
}

.subTitle {
    color: $blackOpacity60;
    font-weight: normal;
}

.formatsInfo {
    color: $blackOpacity38;
    font-weight: normal;
}

.rating {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 0;
    font-size: 15px;

    strong {
        color: $yellow900;
    }

    span {
        color: $blackOpacity60;
    }

    .notFilledClassName {
        svg {
            fill: $yellow900;
        }
    }
}

.txt {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.60);
}

.mt24 {
    margin-top: 24px;
}

.keyWords {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    button {
        margin-right: 4px;
        margin-bottom: 4px;
    }
}

.tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;

    span {
        display: flex;
        padding: 4px 8px 4px 6px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: #E1F1B5;
        color: $blackOpacity88;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.4px;

        svg {
            margin-right: 4px;
            fill: $blackOpacity60;
        }
    }

    &.bundleTag {
        span {
            background: $blue100;
        }
    }
}

.cover {
    margin-top: 36px;
}


.mt16 {
    margin-top: 16px;
}

.priceBox {
    margin-top: 20px;
    text-align: center;

    .oldPrice {
        color: $blackOpacity38;
        font-size: 22px;
        white-space: nowrap;
        margin-right: 8px;

        em {
            text-decoration: line-through;
        }
    }

    .price {
        font-size: 28px;
        white-space: nowrap;
        font-weight: bold;
    }
}

.quantityBox {
    width: 120px;
    height: 40px;
    padding: 8px;
    margin: 0 auto;
    border-radius: 8px;
    border: 1px solid $blackOpacity60;
    display: flex;
    margin-top: 24px;

    button {
        min-width: 24px;
        height: 24px;
        border: 0;
        background: transparent;
        color: $blackOpacity60;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        &:last-child {
            color: $blackOpacity88;
        }
    }

    input {
        width: 100%;
        height: 100%;
        background: transparent;
        line-height: 24px;
        text-align: center;
        padding: 0 10px;
        border: 0;
        font-size: 16px;
        font-weight: 700;
        color: $blackOpacity88;

        &:focus {
            outline: none;
        }
    }
}


.subTxt {
    margin-top: 16px;
    color: $blackOpacity88;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;

    span {
        color: $blackOpacity60;
        font-weight: 400;
    }
}

.btnTxt {
    margin-top: 16px;
    text-align: center;

    span {
        color: $blackOpacity88;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        padding: 6px 12px;
        display: inline-flex;
        border-radius: 12px;
        background: $blackOpacity12;
    }
}

.bundles {
    margin-top: 24px;

    h3 {
        margin-bottom: 10px;
    }
}

.book {
    margin-top: 8px;
}

.bookBox {
    display: flex;
    padding: 12px;
    border-radius: 8px;
    background: $white;
    border: 1px solid $blackOpacity12;


    picture {
        min-width: 80px;
        margin-right: 16px;

        img {
            height: 80px;
            width: 80px;
            border-radius: 4px;
            object-fit: cover;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.30), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
        }
    }

    .title {
        color: $blackOpacity88;
    }
}
