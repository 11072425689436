@import "../../../../../styles/variables";

$headHeight: 40vh;


.chapters {
    h4 {
        color: $blackOpacity38;
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        padding: 0 8px;
        margin-bottom: 12px;
    }
}

.chapterBook {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    img {
        width: 80px;
        height: 80px;
        max-height: 80px;
        min-width: 80px;
        object-fit: cover;
        border-radius: 4px;
        margin-right: 16px;
    }

    .info {
        display: flex;
        justify-content: center;
        flex-direction: column;
        min-width: 0;

        h3 {
            overflow: hidden;
            color: $blackOpacity87;
            text-overflow: ellipsis;
            white-space: nowrap;

            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15px;
        }

        .author {
            display: block;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            color: $blackOpacity60;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 8px;
        }

        .duration {
            display: block;
            color: $blackOpacity38;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0.4px;
        }
    }
}

.chapterList {
    margin: 0;
    padding: 0;

    li {
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .active {
        border-radius: 8px;
        background: $blackOpacity04;
    }

    .activeIcon {
        margin-right: 8px;

        svg {
            fill: $blackOpacity38;
        }
    }

    .chapterTitle {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 0;
        color: $blackOpacity87;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.15px;
    }

    .chapterInfo {
        color: $blackOpacity60;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        display: block;
        padding-left: 12px;
    }
}
