@import "../../../styles/variables";

.roundBtn {
    background-color: $primaryColor;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    i {
        width: auto !important;
        height: auto !important;
    }

    svg {
        fill: $white;
    }

    &:focus {
        outline: none;
    }
}

.s {
    width: 56px;
    height: 56px;

    svg {
        width: 24px;
        height: 24px;
    }
}

.m {
    width: 64px;
    height: 64px;

    svg {
        width: 40px;
        height: 40px;
    }
}

.l {
    width: 96px;
    height: 96px;

    svg {
        width: 48px;
        height: 48px;
    }
}

.red {
    background-color: $red;

    svg {
        fill: $white;
    }
}

.yellow {
    background-color: $yellow;

    svg {
        fill: $black;
    }
}

.green {
    background-color: $green;

    svg {
        fill: $white;
    }
}

.black {
    background-color: $blackOpacity88;

    svg {
        fill: $white;
    }
}

.grey {
    background-color: $grayF2;

    svg {
        fill: $blackOpacity38;
    }
}
