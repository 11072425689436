@import "../../../../../styles/variables";

.narrators {
    h6 {
        color: rgba(0, 0, 0, 0.60);
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.1px;
    }
}

.narratorItem {
    margin-top: 8px;
    display: flex;
    align-items: center;
    padding: 8px 16px 8px 8px;
    border-radius: 8px;
    border: 1px solid transparent;

    &.active {
        border: 1px rgba(0, 0, 0, 0.12);
        background: rgba(0, 0, 0, 0.04);
    }

    .avatar {
        width: 40px;
        height: 40px;
        min-width: 40px;
        margin-right: 8px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: cover;
            display: block;
        }
    }

    .info {
        margin-right: 8px;
        padding-right: 16px;
        color: rgba(0, 0, 0, 0.38);
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.4px;
        width: 100%;

        span {
            color: rgba(0, 0, 0, 0.87);
            font-size: 14px;
            font-weight: 500;
            line-height: 1.4px;
            letter-spacing: 0.15px;
        }
    }

    .lang {
        width: 20px;
        min-width: 20px;
        margin-right: 8px;

        img {
            width: 100%;
            display: block;
        }
    }

    .duration {
        color: rgba(0, 0, 0, 0.60);
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.4px;
        white-space: nowrap;
    }
}
