@import "../../styles/variables.scss";

.loader {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    background: $primaryColor;
    padding: 40px 0;

    .loading {
        flex: 1;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;

        svg {
            fill: $white;
        }
    }

    .footer {
        min-height: 44px;
        text-align: center;
    }
}
