@import "../../../../../styles/variables";

.narrator {
    display: flex;
    align-items: flex-end;
    background: transparent;
    border: none;
    padding: 0;
    text-align: left;

    picture {
        position: relative;
        width: 100px;
        height: 100px;
        min-width: 70px;
        border: 4px solid $white;
        overflow: hidden;
        border-radius: 50%;
        box-shadow: 0 11px 15px 0 rgba(0, 0, 0, 0.20), 0 9px 46px 0 rgba(0, 0, 0, 0.12), 0 24px 38px 0 rgba(0, 0, 0, 0.14);

    }
}

.mainImg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.lang {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: -2px;
    left: -2px;
    padding: 2px;
    background-color: $white;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    width: 18px;
    height: 18px;

    img {
        object-fit: contain;
        width: 100%;
    }
}

.name {
    padding-left: 8px;
    padding-bottom: 4px;
    max-width: 200px;

    .title {
        color: $blackOpacity38;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.4px;
        display: block;
        margin-bottom: 2px;
    }

    p {
        color: $blackOpacity60;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.1px;
    }
}

.ico {
    position: relative;
    bottom: -4px;
}
