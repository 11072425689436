@import "../../../styles/variables.scss";

.avatarContainer {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    //overflow: hidden;
    border-radius: 50%;

    &.withoutAvatar {
        background-color: $grayF2;
    }

    img {
        display: block;
        border-radius: 50%;
        object-fit: cover;
    }

    .defIcon {
       svg {
           fill: $blackOpacity38 !important;
           width: 100%;
           height: 100%;
       }
    }

    input {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        z-index: 2;
    }
}

.editIcon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    right: 0;
    margin: 2px;
    cursor: pointer;
    background-color: #3699F5;
    border: 1px solid $white;
    border-radius: 50%;
    z-index: 1;

    svg {
        fill: $white;
        width: 70%;
        height: 70%;
    }
}

.avatarInitials {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    color: $blackOpacity60;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    line-height: 1.1;
}

.avatarImage {
    width: 100%;
    height: 100%;
    background-size: cover;
}

.default-avatar-icon {
    width: 100%;
    height: 100%;
    background-size: cover;
}


.XL3 {
    width: 128px;
    height: 128px;
    font-weight: 700;
    font-size: 60px;

    .editIcon {
        width: 32px;
        height: 32px;
    }
}

.XL2 {
    width: 96px;
    height: 96px;
    font-weight: 700;
    font-size: 44px;

    .editIcon {
        width: 24px;
        height: 24px;
    }
}

.XL {
    width: 64px;
    height: 64px;
    font-weight: 700;
    font-size: 30px;
    align-content: center;

    .editIcon {
        width: 16px;
        height: 16px;
    }
}

.L {
    width: 48px;
    height: 48px;
    font-weight: 700;
    font-size: 22px;
    align-content: center;

    .editIcon {
        width: 16px;
        height: 16px;
    }
}

.M {
    width: 32px;
    height: 32px;
    font-weight: 700;
    font-size: 16px;
    align-content: center;

    .editIcon {
        display: none;
    }
}

.S {
    width: 24px;
    height: 24px;
    font-weight: 700;
    font-size: 12px;
    align-content: center;

    .editIcon {
        display: none;
    }
}

.XS {
    width: 16px;
    height: 16px;
    font-weight: 700;
    font-size: 8px;
    align-content: center;

    .editIcon {
        display: none;
    }
}





