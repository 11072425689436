@import "../../styles/variables";

.audioPlayer {
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    z-index: 90;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    padding: 8px 8px 0 8px;

    .inner {
        padding: 14px 0 24px;
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: $mainBg;
        border-radius: 16px 16px 0 0;
        border: 1px solid $blackOpacity12;
        box-shadow: 0 11px 15px 0 rgba(0, 0, 0, 0.20), 0 9px 46px 0 $blackOpacity12, 0 24px 38px 0 rgba(0, 0, 0, 0.14);
    }

    &.isMini {
        background-color: $purple;
        top: auto;
        bottom: 0;
        min-height: auto;
        height: auto;
        padding: 8px;

        .inner {
            border-radius: 0;
            border: 0;
            box-shadow: none;
            padding: 0;
        }
    }
}

.errMsg {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    color: $red;
    min-height: 80vh;
    text-align: center;
    line-height: 22px;
    font-size: 18px;
}


.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px;

    button {
        background-color: transparent;
        border: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
