@import "../../../styles/variables";

.input {
    width: 100%;
    height: 40px;
    padding: 12px;
    line-height: 16px;
    flex-shrink: 0;
    border-radius: 4px;
    background-color: $mainBg;
    border: 1px solid #d2d2c4;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 400;

    &:focus {
        outline: none;
        border-color: rgba(0, 0, 0, 0.67);
    }

    &.error {
        border-color: $red;
    }

    &.white {
        background-color: $white;
    }

    &.large {
        height: 56px;
        font-size: 16px;
        padding: 12px 16px;
    }
}
