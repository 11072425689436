@import "../../../styles/variables";

$headHeight: 40vh;

.some {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 16px;
}

.head {
    max-height: $headHeight;
}

.cover {
    display: flex;
    width: 100%;
    justify-content: center;
    max-height: 280px;
    height: calc($headHeight - 70px);
    flex: 1;

    img {
        min-height: 100%;
        display: block;
        box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.20), 0 6px 30px 0 rgba(0, 0, 0, 0.12), 0 16px 24px 0 rgba(0, 0, 0, 0.14);
    }
}

.narratorBox {
    min-height: 100px;
    position: relative;
    z-index: 12;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -50px;
    flex: 1;

    .recBtnBox {
        margin-left: 16px;

        > button {
            max-width: 130px;
        }
    }
}

.progressBox {
    margin-top: 18px;
}

.audioControls {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    button:disabled {
        svg {
            fill: $blackOpacity38;
        }
    }
}

.prevBackBtn {
    width: 40px;
    height: 40px;
    border: 0;
    background: transparent;
    padding: 0;

    svg {
        fill: $black;
    }
}

.secBtn {
    width: 48px;
    height: 48px;
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0 20px;

    svg {
        fill: $black;
    }
}

.pausePlay {
    width: 80px;
    min-width: 80px;
    height: 80px;
    background: $black;
    border: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    svg {
        fill: $white;
    }
}

.titleBox {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .titleInfo {
        min-width: 0;
    }

    .titleButton {
        padding-left: 12px;
    }

    .title {
        color: $blackOpacity87;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .author {
        margin-top: 2px;
        color: $blackOpacity60;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.actions {
    display: flex;
    width: 100%;
    max-width: 290px;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;

    .icoBtn {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 0;
        padding: 0;

        &:disabled {
            svg {
                fill: $blackOpacity38;
            }
        }
    }

    svg {
        fill: $black;
    }
}

.selectNarratorBtn {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    height: 48px;
    border-radius: 24px;
    background-color: $grayLight;
    margin: 0 10px;
    border: 0;
    color: r$blackOpacity88;
    font-size: 14px;
    font-weight: 700;

    span {
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 110px;
        margin-right: 6px;
    }

    picture {
        display: block;
        margin-right: 8px;
    }

    img {
        min-width: 32px;
        width: 32px;
        height: 32px;
        border: 2px solid $white;
        border-radius: 50%;
        object-fit: cover;
        display: block;
    }
}

.audioTag {
    position: absolute;
    left: -100%;
    opacity: 0;
}
