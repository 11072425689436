@font-face {
    font-family: 'Calibri';
    src:url('calibri/Calibri.woff') format('woff'),
    url('calibri/Calibri.woff2') format('woff2'),
    url('calibri/Calibri.svg#Calibri') format('svg'),
    url('calibri/Calibri.eot'),
    url('calibri/Calibri.eot?#iefix') format('embedded-opentype'),
    url('calibri/Calibri.ttf') format('truetype');

    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibri';
    src:url('calibri/Calibri-Bold.woff') format('woff'),
    url('calibri/Calibri-Bold.woff2') format('woff2'),
    url('calibri/Calibri-Bold.svg#Calibri-Bold') format('svg'),
    url('calibri/Calibri-Bold.eot'),
    url('calibri/Calibri-Bold.eot?#iefix') format('embedded-opentype'),
    url('calibri/Calibri-Bold.ttf') format('truetype');

    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Iskra';
    src:url('iskra/IskraCYR-Regular.woff') format('woff'),
    url('iskra/IskraCYR-Regular.woff2') format('woff2'),
    url('iskra/IskraCYR-Regular.eot'),
    url('iskra/IskraCYR-Regular.eot?#iefix') format('embedded-opentype'),
    url('iskra/IskraCYR-Regular.otf') format('truetype');

    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Iskra';
    src:url('iskra/IskraCYR-Bold.woff') format('woff'),
    url('iskra/IskraCYR-Bold.woff2') format('woff2'),
    url('iskra/IskraCYR-Bold.eot'),
    url('iskra/IskraCYR-Bold.eot?#iefix') format('embedded-opentype'),
    url('iskra/IskraCYR-Bold.otf') format('truetype');

    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
