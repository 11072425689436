@import "../../../styles/variables";

.radioGroup {
    display: flex;
    align-items: center;
    padding: 2px;
    overflow: hidden;
    border-radius: 8px;
    background: $blackOpacity12;
}

.radioBtn {
    flex: 1;
    background-color: transparent;
    border: none;
    padding: 8px;
    cursor: pointer;
    height: 36px;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    color: $blackOpacity60;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.15px;
    border-radius: 6px;
    white-space: nowrap;

    .icon {
        width: 20px;
        height: 20px;
        margin-right: 4px;
    }

    svg {
        fill: $blackOpacity38;
        width: 100%;
        height: 100%;
    }

    img {
        width: 100%;
        max-height: 20px;
        border-radius: 4px;
    }


    &.selected {
        background-color: $white;
        color: $blackOpacity88;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.20), 0 2px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);

        svg {
            fill: $blackOpacity60;
        }
    }
}
