@import "../../../styles/variables";

.root {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    padding: 24px;
}

.info {
    text-align: center;
    max-width: 380px;
    margin: 0 auto 40px;

    h1 {
        color: $primaryColor;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        margin: 0 0 8px;
    }

    p {
        color: $blackOpacity60;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
    }

    span {
        color: $blackOpacity87
    }

    svg {
        margin-bottom: 40px;
        font-weight: 700;
    }
}

.mailField {
    margin-bottom: 32px;
}

.time {
    margin-top: 60px;
    margin-bottom: 16px;
    color: $blackOpacity60;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;

    span {
        color: $blackOpacity87;
        font-weight: 700;
    }
}

.error {
    margin-top: 6px;
    color: $red;
}
